import React from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import styled from 'styled-components';
import { ArrowRight } from 'react-feather';
import { TextBold24 } from '../../VuexyDesign/atoms/Text';

import { ProOutlineButton } from '../../VuexyDesign/atoms/_index';
import Button from '../../VuexyDesign/atoms/Button';
import { noDesktop } from '../../style/constants';

const MakeYourChoice = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <div>
        <div className="d-flex flex-column py-3">
          <TextContainer className="d-flex align-items-center pb-3">
            <TitleContainer>
              <h2 className="custom-heading-font-2">
                {t('home:makeYourChoice') as string}
              </h2>
            </TitleContainer>
            <DistributionTextContainer>
              <span className="custom-font-text-medium-normal">
                {t('home:UpgradeToVirppPro') as string}
              </span>
            </DistributionTextContainer>
          </TextContainer>
          <ChoicesContainer className="d-flex">
            <div className="demoCommunity">
              <div
                className="p-2 d-flex flex-column align-items-start"
                style={{ borderRadius: '22px', border: '2px solid #ebe9f1' }}
              >
                <div style={{ marginTop: '2rem' }}>
                  <Image
                    alt="the demo community"
                    src="/static/images/home/demoCommunity_mobile.webp"
                    height={100}
                    width={170}
                  />
                </div>
                <TextBold24 className="my-1">
                  {t('home:demoCommunity') as string}
                </TextBold24>
                <span className="custom-font-text-medium-normal mb-2">
                  {t('home:thePerfectPlatform') as string}
                </span>

                <Link passHref href="/explore">
                  <div className="widthHundred">
                    <Button
                      outline
                      color="primary"
                      className="d-flex align-items-center widthHundred"
                    >
                      <div className="d-flex align-items-end">
                        {t('home:discoverMore') as string}
                        <ArrowRight
                          style={{ marginLeft: '10px' }}
                          size={14}
                          color="#f71454"
                        />
                      </div>
                    </Button>
                  </div>
                </Link>
              </div>
            </div>
            <Distribution>
              <DistributionCardWrapper style={{ borderRadius: '22px' }}>
                <DistributionCard style={{ borderRadius: '20px' }}>
                  <div className="p-2 d-flex flex-column align-items-start">
                    <div className="w-100 d-flex justify-content-center">
                      <DistributionSub className="rounded">
                        <span className="custom-font-text-small-medium text-white px-1">
                          {t('home:popular') as string}
                        </span>
                      </DistributionSub>
                    </div>
                    <Image
                      alt="Streaming Platforms"
                      src="/static/images/home/streamingPlatforms_mobile.webp"
                      height={100}
                      width={165}
                    />
                    <GradientText className="my-1">
                      {t('home:distributionPro') as string}
                    </GradientText>
                    <span className="mb-2 custom-font-text-medium-normal">
                      {t('home:theUltimateSolution') as string}
                    </span>

                    <Link passHref href="/music-distribution">
                      <div className="widthHundred">
                        <ReleaseButton className="d-flex align-items-center widthHundred">
                          <div className="d-flex align-items-center">
                            {t('home:discoverMore') as string}{' '}
                            <GradientArrow
                              style={{ marginLeft: '8px' }}
                              size={14}
                            />
                          </div>
                        </ReleaseButton>
                      </div>
                    </Link>
                  </div>
                </DistributionCard>
              </DistributionCardWrapper>
            </Distribution>
          </ChoicesContainer>
        </div>
      </div>
    </>
  );
};

export default MakeYourChoice;

const DistributionCard = styled.div`
  background: linear-gradient(
    135deg,
    rgb(252, 243, 248) 0%,
    rgb(245, 248, 254) 100%
  );
`;

const TitleContainer = styled.div`
  width: 50%;
  @media ${noDesktop} {
    width: 100%;
  }
`;

const ChoicesContainer = styled.div`
  width: 100%;
  @media ${noDesktop} {
    flex-direction: column;
  }

  .demoCommunity {
    width: 50%;
    margin-right: 1rem;
    @media ${noDesktop} {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .widthHundred {
    @media ${noDesktop} {
      width: 100%;
    }
  }
`;

const TextContainer = styled.div`
  @media ${noDesktop} {
    align-items: center;
    flex-direction: column;
  }
`;

const Distribution = styled.div`
  width: 50%;
  margin-left: 1rem;
  @media ${noDesktop} {
    width: 100%;
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;

const DistributionTextContainer = styled.div`
  width: 50%;
  margin-left: 3rem;
  @media ${noDesktop} {
    width: 100%;
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;

const DistributionSub = styled.div`
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  position: relative;
  top: -36px;
  padding: 5px 0;
  width: 80px;
`;

const DistributionCardWrapper = styled.div`
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  padding: 2px;
`;

const GradientText = styled(TextBold24)`
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const GradientArrow = styled(ArrowRight)`
  color: #5246c5;
`;
const ReleaseButton = styled(ProOutlineButton)`
  &:hover {
    ${GradientArrow} {
      color: white;
    }
  }
`;
