import React from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { ArrowRight } from 'react-feather';
import { TextBold28 } from '../../VuexyDesign/atoms/Text';
import Button from '../../VuexyDesign/atoms/Button';
import { mobile, noDesktop, useIsMobile } from '../../style/constants';

const ForRecordLabels = () => {
  const { t } = useTranslation('home');
  const isMobile = useIsMobile();
  return (
    <>
      <div>
        <LabelContainer className="d-flex flex-column text-white">
          <Container className="d-flex">
            <div className="textContainer">
              <div className="p-2 d-flex flex-column align-items-start">
                <SubTitle className="text-white">
                  {t('home:forRecordLabels') as string}
                </SubTitle>
                <h2 className="custom-heading-font-2 my-1 text-white">
                  {t('home:recieveDemos') as string}
                </h2>
                <span className="mb-2 custom-font-text-medium-normal text-white">
                  {t('home:useOurWhiteLabel') as string}
                </span>

                <div className="d-flex flex-row align-items-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://business.virpp.com/login"
                  >
                    <Button
                      color="white"
                      className="d-flex align-items-center border"
                    >
                      <>{t('home:discoverMore') as string} </>
                      <ArrowRight size={14} />
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="textContainer">
              <div className="d-flex justify-content-end mb-3">
                <div>
                  {isMobile ? (
                    <Image
                      alt="label dashboard"
                      src="/static/images/home/labelDashboard_mobile.webp"
                      height={195}
                      width={330}
                      loading="lazy"
                    />
                  ) : (
                    <Image
                      alt="label dashboard"
                      src="/static/images/home/labelDashboard_desktop.webp"
                      height={368}
                      width={626}
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            </div>
          </Container>
        </LabelContainer>
        <div className="d-flex justify-content-center">
          <span className="text-muted custom-font-text-small-medium">
            {t('home:trustedByRecordLabels') as string}
          </span>
        </div>
      </div>
    </>
  );
};

export default ForRecordLabels;

const LabelContainer = styled.div`
  background: linear-gradient(37deg, #0a5af5 1.64%, #013bac 100%);
  border-radius: 24px;
  padding: 7rem 0;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  @media ${noDesktop} {
    padding: 2rem 0;
    margin-top: 0;
  }
`;

const SubTitle = styled(TextBold28)`
  @media ${mobile} {
    font-size: 18px !important;
    font-weight: 400;
  }
`;

const Container = styled.div`
  align-items: center;
  @media ${noDesktop} {
    flex-direction: column;
  }

  .textContainer {
    width: 50%;
    @media ${noDesktop} {
      width: 100%;
    }
  }
`;
