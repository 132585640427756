/* eslint-disable import/order */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Container from '../../VuexyDesign/atoms/Container';
import Row from '../../VuexyDesign/atoms/Row';
import Col from '../../VuexyDesign/atoms/Col';
import CardBody from '../../VuexyDesign/atoms/CardBody';

class BlogPosts extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    this.getPosts();
  }

  getPosts() {
    fetch('https://virpp.com/hello/wp-json/wp/v2/posts?_embed&categories=70')
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          posts: data,
        });
      });
  }

  render() {
    return (
      <Container>
        <Row className="match-height">
          {this.state.posts
            .slice(0, 3)
            .map(
              (post: {
                id: string;
                link: string;
                _embedded: any;
                title: any;
                date: string;
                description_meta_attribute_virpp: string;
              }) => (
                <Col sm="12" lg="4" key={`BlogPost-${post.id}`}>
                  <CardCustom key={post.id}>
                    <CartLink href={post.link} target="_blank" rel="noreferrer">
                      <div
                        style={{
                          width: '100%',
                          height: '250px',
                          position: 'relative',
                        }}
                      >
                        <BlogImage
                          src={post._embedded['wp:featuredmedia'][0].source_url}
                          alt="Card cap"
                          fill
                          className="br-5 object-fit-cover"
                          unoptimized
                          style={{
                            maxWidth: '100%',
                          }}
                        />
                      </div>
                      <CardBodyCustom>
                        <CardTitleCustom>{post.title.rendered}</CardTitleCustom>
                        <p className="custom-font-text-small-normal paragraph-color">
                          {post.description_meta_attribute_virpp}
                        </p>
                      </CardBodyCustom>
                    </CartLink>
                  </CardCustom>
                </Col>
              ),
            )}
        </Row>
      </Container>
    );
  }
}

const CardCustom = styled.div`
  border: 0 !important;
  box-shadow: none !important;
  margin-bottom: 50px;
`;

const CartLink = styled.a`
  color: black !important;
  transform-origin: 50% 65%;

  img:hover {
    transform: scale(1.1);
    transition: transform 1s, filter 3s ease-in-out;
  }
`;

const BlogImage = styled(Image)`
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const CardTitleCustom = styled.div`
  font-size: 1.5rem !important;
  font-weight: bolder;
  margin-top: 11px;
`;

const CardBodyCustom = styled(CardBody)`
  padding: 0;
`;

export default BlogPosts;
