import React from 'react';
// import Image from 'next/image';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Trans from 'next-translate/Trans';

const getSizeForStars = (
  size: 'small' | 'medium' | 'large' | undefined,
  rating: string | undefined,
) => {
  if (size) {
    return size;
  }
  if (rating) {
    return 'medium';
  }
  return 'large';
};
const StarRating = ({
  className,
  stars,
  rating,
  size,
  noText = false,
}: StarRatingProps) => (
  <div
    className={`trusted-by position-relative align-items-center d-flex ${className} flex-column flex-md-row`}
  >
    <Stack spacing={1}>
      <Rating
        name="half-rating-read"
        defaultValue={stars || 4.5}
        precision={0.1}
        readOnly
        size={getSizeForStars(size, rating)}
        sx={{
          '& .MuiRating-iconFilled': {
            color: '#FFD315',
          },
        }}
      />
    </Stack>
    {noText ? (
      <></>
    ) : (
      <>
        {rating ? (
          <span className="mb-0">{`(${stars} ${rating})`}</span>
        ) : (
          <span className="artist-rate ml-1 mb-0">
            <Trans i18nKey="pricing:trustedBy" />
          </span>
        )}
      </>
    )}
  </div>
);

interface StarRatingProps {
  className?: string;
  rating?: string;
  stars?: number;
  size?: 'small' | 'medium' | 'large';
  noText?: boolean;
}

export default StarRating;
