import React, { useState } from 'react';
import { Minus, Plus } from 'react-feather';
/* eslint-disable @typescript-eslint/no-explicit-any */
const IndexAccordion = ({ title, content }: { title: any; content: any }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="pb-1">
      <div
        className="p-1 rounded bg-white"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="font-weight-bold">{title}</div>
        <div>{isActive ? <Minus size={24} /> : <Plus size={24} />}</div>
      </div>
      {isActive && (
        <div className="p-1" style={{ maxWidth: '1400px' }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default IndexAccordion;
