import React from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { ArrowRight } from 'react-feather';
import styled from 'styled-components';
import { useIsProMember } from '../../api';
import { TextBold14, TextBold16 } from '../../VuexyDesign/atoms/Text';
import { Row } from '../../VuexyDesign/atoms/_index';
import { noDesktop, useIsMobile } from '../../style/constants';

const ReleaseYourMusic = () => {
  const { t } = useTranslation('home');
  const isPro = useIsProMember();
  const isMobile = useIsMobile();
  return (
    <>
      <div>
        <Row className="align-items-center m-0">
          <div>
            <div className="d-flex flex-column align-items-center">
              <Container className="d-flex">
                <div className="my-5 imageContainer">
                  {isMobile ? (
                    <Image
                      alt="distribution"
                      src="/static/images/home/monetizeYourMusic_mobile.webp"
                      height={287}
                      width={308}
                      loading="lazy"
                    />
                  ) : (
                    <Image
                      alt="distribution"
                      src="/static/images/home/monetizeYourMusic_Desktop.webp"
                      height={552}
                      width={593}
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="d-flex flex-column textContainer justify-content-center">
                  <div className="py-2 d-flex flex-column align-items-start mb-3">
                    <DistributionSub className="rounded">
                      <TextBold14 className="text-white px-1">
                        {t('home:distributionPro') as string}
                      </TextBold14>
                    </DistributionSub>
                    <h2 className="custom-heading-font-2 my-1">
                      {t('home:monetizeYourMusic') as string}
                    </h2>
                    <span className="mb-2 custom-font-text-medium-normal">
                      {t('home:ourProUsers') as string}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                      <Link
                        passHref
                        href={isPro ? '/distribution/welcome' : '/become-pro'}
                      >
                        <div>
                          <DistributionButton className="cursor-pointer rounded d-flex align-items-center">
                            <TextBold16 className="text-white">
                              {t('home:distributeYourMusic') as string}
                            </TextBold16>
                          </DistributionButton>
                        </div>
                      </Link>
                      <div className="ml-1 cursor-pointer">
                        <Link passHref href="/how-it-works/releasing-music">
                          <div>
                            <TextBold16>
                              {t('home:learnMore') as string}{' '}
                              <ArrowRight size={14} />
                            </TextBold16>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default ReleaseYourMusic;

const DistributionSub = styled.div`
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
`;

const DistributionButton = styled.div`
  background: linear-gradient(315.97deg, #1159f1 1.64%, #f41657 100%);
  padding: 0.786rem 1.5rem;
  :hover {
    box-shadow: 0 8px 25px -8px #8338a4;
    transition: box-shadow 0.2s;
  }
`;

const Container = styled.div`
  align-items: center;
  @media ${noDesktop} {
    flex-direction: column;
  }

  .textContainer {
    width: 50%;
    @media ${noDesktop} {
      width: 100%;
    }
  }

  .imageContainer {
    width: 50%;
    @media ${noDesktop} {
      display: flex;
      justify-content: center;
    }
  }
`;
