import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { NextPage } from 'next';
import styled from 'styled-components';
import loadNamespaces from 'next-translate/loadNamespaces';
import { useIsLoggedIn } from '../api/index';
import { withAuthSync } from '../utils/auth';
import BrowserPageTitle from '../components/common/BrowserPageTitle';
import Container from '../VuexyDesign/atoms/Container';
import Row from '../VuexyDesign/atoms/Row';
import GetDiscovered from '../components/Home/GetDiscovered';
import MakeYourChoice from '../components/Home/MakeYourChoice';
import DemoCommunity from '../components/Home/DemoCommunity';
import HeroSection from '../components/Home/HeroSection';
import CtaBanner from '../VuexyDesign/molecules/CtaBanner';
import BlogPosts from '../components/Home/BlogPosts';
import Partners from '../components/Partners/Partners';
import ForRecordLabels from '../components/Home/ForRecordLabels';
import styles from '../style/scss/modules/HomePage.module.scss';
import { mobile, noDesktop } from '../style/constants';
import Button from '../VuexyDesign/atoms/Button';
import IndexFaq from '../components/Home/IndexFaq';
import { DistributionLandingTrusted } from '../VuexyDesign/organisms/_index';
import ReleaseYourMusic from '../components/Home/ReleaseYourMusic';
import renderMetaTags from '../utils/renderMetaTags';
import Footer from '../components/Footer';
import RenderStructuredData from '../utils/renderStructuredData';
import { VirpPageContext } from './_app';

const Homev2: NextPage = () => {
  const userLoggedIn = useIsLoggedIn();
  const { t } = useTranslation('home');
  return (
    <PageContainer fluid className={`${styles.container} overflow-hidden`}>
      <BrowserPageTitle description={t('home:meta.description') as string}>
        {t('home:meta.title') as string}
      </BrowserPageTitle>

      {renderMetaTags({
        'og:title': `${t('home:meta.title') as string}`,
        'og:image':
          'https://tracks.virpp.com/images/Social%20Thumb%202-min.jpg',
        'og:description': `${t('home:meta.description') as string}`,
        'og:image:alt': `${t('home:meta.title') as string}`,
        'twitter:title': `${t('home:meta.title') as string}`,
        'twitter:image':
          'https://tracks.virpp.com/images/Social%20Thumb%202-min.jpg',
        'twitter:description': `${t('home:meta.description') as string}`,
      })}
      <RenderStructuredData
        data={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'VIRPP B.V.',
          description:
            "VIRPP is an online music platform that provides two main services: Demo Community and Distribution PRO. With Demo Community, users can upload their unreleased music demos, connect with other musicians worldwide, get feedback on their demos, gain insights, and have a chance to get discovered by labels. Distribution PRO allows users to distribute their music independently on their own terms to over 100 stores and streaming platforms worldwide, including Spotify, Apple Music, and Tidal. Users can earn money through VIRPP's revenue sharing model and track their progress through the platform.",
          url: 'https://virpp.com',
          logo: 'https://virpp.com/static/images/logo.png',
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Amsterdam',
            addressCountry: 'NL ',
            postalCode: '1096AS',
            streetAddress: 'H.J.E. Wenckebachweg 200',
          },
          sameAs: [
            'https://www.instagram.com/virpp/',
            'https://www.youtube.com/channel/UCRtGAKg8yG44-QXvRsuDQMA',
            'https://www.facebook.com/ViralPromoPlatform/',
            'https://www.linkedin.com/company/virpp/',
          ],
        }}
      />
      <div className="d-flex align-items-end position-relative">
        <HeroSection />
      </div>

      <ContainerBox className="align-items-center smallPadding py-4">
        <MakeYourChoice />
      </ContainerBox>

      <GreyContainer>
        <Row className="align-items-center smallPadding py-4">
          <GetDiscovered />
        </Row>
      </GreyContainer>

      <ContainerBox className="align-items-center smallPadding py-4">
        <DemoCommunity />
      </ContainerBox>

      <DistributionContainer>
        <ContainerBox className="align-items-center smallPadding py-4">
          <ReleaseYourMusic />
        </ContainerBox>
      </DistributionContainer>

      <ContainerBox className="align-items-center smallPadding py-4">
        <ForRecordLabels />
        <Partners />
      </ContainerBox>

      <GreyContainer>
        <ContainerBox className="align-items-center PageContainer">
          <DistributionLandingTrusted
            title={t('organisms:listenToOurUsers') as string}
            subtitle={t('home:dontTakeOurWord') as string}
            data={trustedUsers}
          />
        </ContainerBox>
      </GreyContainer>

      <ContainerBox className="align-items-center smallPadding py-4">
        <div className="text-center mb-3">
          <h2 className="custom-heading-font-2 ">
            {t('home:virppIsTrusted') as string}
          </h2>
          <span className="custom-font-text-medium-normal">
            {t('home:exploreInterviews') as string}
          </span>
        </div>
        <Row className="match-height mt-0 pt-0">
          <BlogPosts />
          <Button
            className="mx-auto mb-4"
            color="primary"
            href="https://virpp.com/hello/blog/"
          >
            {t('home:viewBlogPosts') as string}
          </Button>
        </Row>
      </ContainerBox>

      <GreyContainer className="d-flex mb-0 justify-content-center ">
        <ContainerBox className="align-items-center smallPadding py-4">
          <div className="d-flex flex-column ">
            <IndexFaq />
            <div>
              <CtaBanner
                title={t('home:unlockYourMusicPotential') as string}
                subtitle={t('home:joinTheCommunity') as string}
                button
                buttonColor="light"
                buttonText={`${
                  userLoggedIn
                    ? (t('home:uploadNow') as string)
                    : (t('home:getStarted') as string)
                }`}
                link={`${userLoggedIn ? '/upload-landing' : '/register'}`}
              />
            </div>
          </div>
        </ContainerBox>
      </GreyContainer>
      <Footer />
    </PageContainer>
  );
};

const PageContainer = styled(Container)`
  margin-bottom: -70px;

  .smallPadding {
    padding: 0 1.5rem;
    @media ${noDesktop} {
      padding: 0 1rem;
    }
  }
`;

const ContainerBox = styled.div`
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 4rem 0;
  @media ${noDesktop} {
    padding: 1rem 0;
  }
`;

const GreyContainer = styled.div`
  background-color: #f8f8f8;
`;

const DistributionContainer = styled.div`
  background: linear-gradient(
    135deg,
    rgb(252, 243, 248) 0%,
    rgb(245, 248, 254) 100%
  ) !important;
  padding: 2px;
  @media ${mobile} {
    background: white !important;
  }
`;

Homev2.getInitialProps = async (ctx: VirpPageContext) => {
  const getTranslations = await loadNamespaces({
    ...ctx,
    pathname: '/',
  });
  return { ...getTranslations };
};

export default withAuthSync(Homev2, {
  protected: false,
});

export interface TrustedArtist {
  id: number;
  imgUrl: string;
  logoUrl: string;
  name: string;
  description: string;
  job: string;
}

const trustedUsers: TrustedArtist[] = [
  {
    id: 1,
    imgUrl: '/static/images/distribution-landing/user-profile1.webp',
    logoUrl: '/static/images/distribution-landing/user-profile1.webp',
    name: 'Jameson Andrews',
    description: 'organisms:trustedArtists.jamesonAndrews',
    job: 'organisms:trustedArtists.artistOnVirpp',
  },
  {
    id: 2,
    imgUrl: '/static/images/distribution-landing/user-profile2.webp',
    logoUrl: '/static/images/distribution-landing/user-profile2.webp',
    name: 'Lamas',
    description: 'organisms:trustedArtists.lamas',
    job: 'organisms:trustedArtists.collabViaVirpp',
  },
  {
    id: 3,
    imgUrl: '/static/images/distribution-landing/user-profile3.webp',
    logoUrl: '/static/images/distribution-landing/user-profile3.webp',
    name: 'DJ Xquizit',
    description: 'organisms:trustedArtists.djXquizit',
    job: 'organisms:trustedArtists.artistOnVirpp',
  },

  {
    id: 4,
    imgUrl: '/static/images/home/johnSmith.webp',
    logoUrl: '/static/images/home/johnSmith.webp',
    name: 'John Smith',
    description: 'organisms:trustedArtists.johnSmith',
    job: 'organisms:trustedArtists.singerSongwriter',
  },
  {
    id: 5,
    imgUrl: '/static/images/home/sarahJohnson.webp',
    logoUrl: '/static/images/home/sarahJohnson.webp',
    name: 'Sara Johnson',
    description: 'organisms:trustedArtists.sarahJohnson',
    job: 'organisms:trustedArtists.electronicMusicProducer',
  },
  {
    id: 6,
    imgUrl: '/static/images/home/davidLee.webp',
    logoUrl: '/static/images/home/davidLee.webp',
    name: 'David Lee',
    description: 'organisms:trustedArtists.davidLee',
    job: 'organisms:trustedArtists.hiphopArtist',
  },
];
