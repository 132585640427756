/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { ArrowRight } from 'react-feather';
import Image from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import Container from '../../VuexyDesign/atoms/Container';
import Row from '../../VuexyDesign/atoms/Row';
import Button from '../../VuexyDesign/atoms/Button';
// eslint-disable-next-line import/order
import {
  Desktop,
  mobile,
  noDesktop,
  useIsDesktop,
} from '../../style/constants';
import {
  TextBold12,
  TextBold14,
  TextRegular12,
} from '../../VuexyDesign/atoms/Text';
import { Logged } from '../../utils/Logged';
import StarRating from '../Pricing/StarRating';

export function HeroSection() {
  const { t } = useTranslation('home');
  const [isImageLoaded, setImageLoaded] = useState(false);
  const isDesktop = useIsDesktop();

  return (
    <>
      <div>
        <Overlay style={{ backgroundColor: '#F8F8F8' }} />
      </div>

      <HeroContainer className="position-relative text-dark justify-content-between z-1">
        <Row className="flex-column flex-md-row flex-grow-1">
          <HeroTextsContainer>
            <CustomContainer>
              <div>
                <span className="custom-font-text-large-medium custom-gradient-color">
                  {t('home:yourMusic') as string}
                </span>
              </div>
              <div>
                <h1 className="custom-heading-font-1 m-0 mb-1">
                  {t('home:unlessYourSound') as string}
                </h1>

                <ButtonsAndFeaturesContainer
                  className={`${isDesktop ? 'mb-2' : ''} d-flex`}
                >
                  <div>
                    <FeatureContainer>
                      <span
                        style={{ color: 'rgb(36, 36, 36)' }}
                        className={`custom-font-text-medium-normal lead d-flex align-items-center ${
                          isDesktop ? 'w-75' : ''
                        }`}
                      >
                        {t('home:ultimatePlatform') as string}
                      </span>
                    </FeatureContainer>
                  </div>
                  <ButtonsContainer className="d-flex">
                    <Logged
                      loggedOut={
                        <Link passHref href="/register">
                          <div>
                            <GetStartedButton
                              color="primary"
                              text={t('home:getStarted') as string}
                              size="lg"
                              className="mr-1"
                            >
                              <ArrowRight size={18} />
                            </GetStartedButton>
                          </div>
                        </Link>
                      }
                      loggedIn={
                        <Link passHref href="/upload-landing">
                          <div>
                            <GetStartedButton
                              color="primary"
                              text={t('home:startYourJourny') as string}
                              size="lg"
                              className="mr-1"
                            >
                              <ArrowRight size={18} className="ml-25" />
                            </GetStartedButton>
                          </div>
                        </Link>
                      }
                    />
                    <Link passHref href="/how-it-works">
                      <div>
                        <HowItWorksButton
                          color="light"
                          text={t('home:howItWorks') as string}
                          size="lg"
                          className="mb-sm-0 mr-1 bg-white"
                        />
                      </div>
                    </Link>
                  </ButtonsContainer>
                </ButtonsAndFeaturesContainer>
                <TrustedByMobile
                  className={`${isDesktop ? 'my-1' : ''} align-items-center`}
                >
                  <div className="happyUsers">
                    <div className="avatars">
                      <span className="avatar">
                        <Image
                          width={50}
                          height={50}
                          alt="rounded happy user one"
                          src="/static/images/home/testimonial-user-image-3.webp"
                        />
                      </span>
                      <span className="avatar">
                        <Image
                          width={50}
                          height={50}
                          alt="rounded happy user two"
                          src="/static/images/home/testimonial-user-image-2.webp"
                        />
                      </span>
                      <span className="avatar">
                        <Image
                          width={50}
                          height={50}
                          alt="rounded happy user three"
                          src="/static/images/home/testimonial-user-image-1.webp"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center ml-1">
                    <span className="custom-font-text-regular-normal">
                      {t('home:overArtistUseVirpp')}
                    </span>
                  </div>
                </TrustedByMobile>

                <MobileImageContainer className="d-flex justify-content-center">
                  <div className="d-flex justify-content-center relative noDesktop-content">
                    <Image
                      onLoad={() => setImageLoaded(true)}
                      alt="Virpp home banner"
                      fetchPriority="high"
                      width={340}
                      height={380}
                      src="/static/images/home/herosection-mobile-new.png"
                      className="pointer-events-none max-width"
                      priority
                    />
                  </div>
                </MobileImageContainer>
              </div>
            </CustomContainer>
          </HeroTextsContainer>
          <ImageContainer className="d-flex justify-content-center align-items-end relative">
            <Desktop>
              <Image
                fetchPriority="high"
                alt="Virpp home banner"
                src="/static/images/home/herosection-Desktop-new.webp"
                height={657}
                width={586}
                className="pointer-events-none"
                onLoad={() => setImageLoaded(true)}
                priority
              />
            </Desktop>
            {isImageLoaded ? (
              <TestiMonialOverLay>
                <div className="mb-1 d-flex justify-align-content-start">
                  <StarRating stars={5} size="small" noText />
                </div>
                <div className="mb-1">
                  <TextBold14>{t('home:testimonial') as string}</TextBold14>
                </div>
                <div className="d-flex flex-column ">
                  <TextBold12>Mike</TextBold12>
                  <TextRegular12 className="opacity-name">Rapper</TextRegular12>
                </div>
              </TestiMonialOverLay>
            ) : (
              <></>
            )}
          </ImageContainer>
        </Row>
      </HeroContainer>
    </>
  );
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #f8f8f8;
  opacity: 1;
  z-index: 1;
`;
const TestiMonialOverLay = styled.div`
  width: 297px;
  position: absolute;
  bottom: 23px;
  left: 0;
  background: #00000066;
  opacity: 1;
  z-index: 1;
  border-radius: 8px;
  padding: 1rem 1.2rem;
  color: white;
  display: flex;
  flex-direction: column;
  .opacity-name {
    opacity: 0.5;
  }

  @media ${noDesktop} {
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  @media ${mobile} {
    padding: 0.8rem 1.2rem;
    width: calc(100% - 30px);
    max-width: 309px;
    bottom: 30px;
  }
`;
const MobileImageContainer = styled.div`
  @media ${noDesktop} {
    align-items: flex-end;
  }
`;

const FeatureContainer = styled.div`
  margin-bottom: 1rem;
  @media ${noDesktop} {
    margin-bottom: 1rem;
  }
`;

const ButtonsAndFeaturesContainer = styled.div`
  flex-direction: column;
`;

const GetStartedButton = styled(Button)`
  border-radius: 8px !important;
  @media ${noDesktop} {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const HowItWorksButton = styled(Button)`
  border-radius: 8px !important;
  @media ${noDesktop} {
    width: 100%;
  }
`;

const HeroTextsContainer = styled.div`
  width: 50%;
  @media ${noDesktop} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 48rem;
  @media ${noDesktop} {
    height: 0;
    width: 100%;
  }
`;

const CustomContainer = styled.div`
  margin-top: 13rem;

  @media ${noDesktop} {
    text-align: left;
    margin-top: 2rem;
  }
`;
const TrustedByMobile = styled.div`
  display: flex;
  flex-direction: row;
  @media ${noDesktop} {
    margin-left: 0rem;
    flex-direction: column;
  }
  .happyUsers {
    @media ${noDesktop} {
      padding-bottom: 1rem;
    }
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  @media ${noDesktop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;

const HeroContainer = styled(Container)`
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 3rem;

  @media ${mobile} {
    .your-music-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .avatars {
    display: inline-flex;
    flex-direction: row-reverse;
  }

  .avatar {
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
  }

  .avatar:not(:last-child) {
    margin-left: -17px;
  }

  .avatar img {
    width: 100%;
    display: block;
  }

  @media ${noDesktop} {
    padding: 2rem 2rem 0rem 2rem;
  }

  .max-width {
    max-width: 328px;
    margin-left: auto;
    margin-right: auto;
  }

  .noDesktop-content {
    display: none !important;

    @media all and (max-width: 991px) {
      display: block !important;
      &.flexDesktop {
        display: flex !important;
      }
    }
  }
`;

export default HeroSection;
