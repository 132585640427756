import React from 'react';
import Head from 'next/head';
import Script from 'next/script';

interface Author {
  '@type': string;
  name: string;
}
interface Address {
  '@type': string;
  addressLocality: string;
  addressCountry: string;
  postalCode: string;
  streetAddress: string;
}
interface FAQ {
  '@type': 'Question';
  name: string;
  acceptedAnswer: {
    '@type': 'Answer';
    text: string;
  };
}

interface StructuredData {
  '@context': string;
  '@type': string;
  headline?: string;
  description?: string;
  author?: Author[];
  mainEntity?: FAQ[];
  datePublished?: string;
  url?: string;
  logo?: string;
  sameAs?: string[];
  name?: string;
  address?: Address;
}

interface StructuredDataProps {
  data: StructuredData;
}
const RenderStructuredData: React.FC<StructuredDataProps> = ({ data }) => (
  <Head>
    <Script
      key="structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  </Head>
);

export default RenderStructuredData;
