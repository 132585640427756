import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import IndexAccordion from './IndexAccordion';
import { noDesktop } from '../../style/constants';

function FAQ() {
  const { t } = useTranslation('home');
  return (
    <Container className="w-100">
      <div className="d-flex flex-column py-2 align-items-center">
        <h2 className="custom-heading-font-2 text-center mb-3">
          {t('home:frequentlyAsked') as string}
        </h2>
      </div>
      <div className="rounded mb-3">
        {index.map(({ title, content, key }) => (
          <IndexAccordion
            key={key}
            title={t(title) as string}
            content={t(content) as string}
          />
        ))}
      </div>
    </Container>
  );
}

export default FAQ;

const Container = styled.div`
  padding-top: 3rem;
  @media ${noDesktop} {
    padding-top: 0;
  }
`;

const index = [
  {
    key: 1,
    title: 'home:faq.questionOne',
    content: `home:faq.answerOne`,
  },
  {
    key: 2,
    title: 'home:faq.questionTwo',
    content: `home:faq.answerTwo`,
  },
  {
    key: 3,
    title: 'home:faq.questionThree',
    content: `home:faq.answerThree`,
  },
  {
    key: 4,
    title: 'home:faq.questionFour',
    content: `home:faq.answerFour`,
  },
  {
    key: 5,
    title: 'home:faq.questionFive',
    content: `home:faq.answerFive`,
  },
  {
    key: 6,
    title: 'home:faq.questionSix',
    content: `home:faq.answerSix`,
  },
  {
    key: 7,
    title: 'home:faq.questionSeven',
    content: `home:faq.answerSeven`,
  },
  {
    key: 8,
    title: 'home:faq.questionEight',
    content: `home:faq.answerEight`,
  },
];
