import React from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { SuccessStories } from '../Carousel/SuccesStories';
import { noDesktop } from '../../style/constants';
import { Col } from '../../VuexyDesign/atoms/_index';

function ForArtists() {
  const { t } = useTranslation('home');
  return (
    <>
      <Col md={12}>
        <div className="d-flex justify-content-center">
          <TextContainer className="text-center mx-1">
            <h2 className="custom-heading-font-2">
              {t('home:successStories') as string}
            </h2>
            <span className="custom-font-text-medium-normal">
              {t('home:virppHelpsEmergingArtists') as string}
            </span>
          </TextContainer>
        </div>
      </Col>
      <SuccessStories />
    </>
  );
}

const TextContainer = styled.div`
  width: 50%;
  @media ${noDesktop} {
    width: 100%;
  }
`;

export default ForArtists;
