import React from 'react';
import Image from 'next/image';

import styled from 'styled-components';
import { tablet, large, mobile } from '../../style/constants';
import Col from '../../VuexyDesign/atoms/Col';
import Row from '../../VuexyDesign/atoms/Row';

const loadDataSlides = [
  {
    key: 1,
    src: 'https://tracks.virpp.com/images/partner/partner1.png',
  },
  {
    key: 2,
    src: 'https://tracks.virpp.com/images/partner/partner2.png',
  },
  {
    key: 3,
    src: 'https://tracks.virpp.com/images/partner/partner3.png',
  },
  {
    key: 4,
    src: 'https://tracks.virpp.com/images/partner/partner4.png',
  },
  {
    key: 5,
    src: 'https://tracks.virpp.com/images/partner/partner5.png',
  },
  {
    key: 6,
    src: 'https://tracks.virpp.com/images/partner/partner6.png',
  },
  {
    key: 7,
    src: 'https://tracks.virpp.com/images/partner/partner7.png',
  },
  {
    key: 8,
    src: 'https://tracks.virpp.com/images/partner/partner8.png',
  },
  {
    key: 9,
    src: 'https://tracks.virpp.com/images/partner/partner10.png',
  },
  {
    key: 10,
    src: 'https://tracks.virpp.com/images/partner/partner11.png',
  },
  {
    key: 11,
    src: 'https://tracks.virpp.com/images/partner/partner12.png',
  },
  {
    key: 12,
    src: 'https://tracks.virpp.com/images/partner/partner13.png',
  },
];

function Partners() {
  return (
    <Container className="my-0">
      <AvatarContainer>
        <Row className="d-flex justify-content-center">
          {loadDataSlides.map((item) => (
            <Col
              key={item.key}
              xs={4}
              sm={3}
              md={2}
              className="d-flex justify-content-center"
            >
              <AvatarImage
                height={80}
                width={80}
                alt="partners"
                src={item.src}
                loading="lazy"
              />
            </Col>
          ))}
        </Row>
      </AvatarContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #ffe584;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  padding: auto;
  background: #fff;
  max-width: 950px;
  margin-top: 50px;
  margin-bottom: 100px;
  h1 {
    font-size: 1.5rem;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 950px;
  @media ${tablet} {
    max-width: 800px;
  }
  @media ${large} {
    max-width: 950px;
  }
`;

const AvatarImage = styled(Image)`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  @media ${tablet} {
    height: 66px;
    width: 66px;
  }
  @media ${large} {
    height: 80px;
    width: 80px;
  }
  @media ${mobile} {
    height: 45px;
    width: 45px;
  }
`;

export default Partners;
