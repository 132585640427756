import React from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useQuerySpotlights } from '../../api';
import { SuccessStoryUser } from '../../api/lib/api/users';
import { mobile } from '../../style/constants';
import { ProfileLink } from '../common/Link';

export const SuccessStories = styled(() => {
  const { data = [], isFetching } = useQuerySpotlights();
  if (isFetching) {
    return null;
  }

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    accessibility: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: false,
          autoplaySpeed: 0,
          speed: 8000,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="w-100 d-block mx-auto pt-3">
      {data.map((story) => (
        <StoryCard {...story} key={`story-card-${story.user.id}`} />
      ))}
    </Slider>
  );
})``;

const StoryCard = ({
  user,
  image,
  user_image,
}: {
  user: SuccessStoryUser;
  image: string;
  user_image: string;
}) => (
  <Container className="rounded">
    <ProfileLink profileId={user.id} className="text-white">
      <SecondContainer>
        <Image
          className="rounded"
          alt="Card image cap"
          src={image}
          width={250}
          height={300}
        />
        <ColRelative>
          {/* {label.display_name} */}
          {user.username}
        </ColRelative>
        <Flexing>
          <SecondImage
            className="rounded-circle"
            alt="artist-photo"
            src={user_image}
            width={70}
            height={70}
          />
          <p
            className="ml-1 text-left pt-2 pb-1"
            style={{
              fontSize: 12,
              lineHeight: 1,
              fontStyle: 'normal',
              fontWeight: 500,
              color: 'black',
            }}
          >
            {user.description}
          </p>
        </Flexing>
      </SecondContainer>
    </ProfileLink>
  </Container>
);

const Flexing = styled.div`
  display: flex;
  margin-top: -2rem;
`;

const SecondImage = styled(Image)`
  width: 70px;
  height: 70px;
`;

const Container = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  width: 270px;
  max-width: 270px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;

  /* @media ${mobile} {
    width: 275px;
    max-width: 275px;
  } */
`;

const SecondContainer = styled.div`
  /* margin-top: 20px;
  margin-bottom: 20px; */
  min-width: 200px;
`;

const ColRelative = styled.div`
  text-align: center;
  overflow-wrap: break-word;
  width: 170px;
  height: 40px;
  position: relative;
  top: -17rem;
  left: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
  color: #ffffff;

  @media ${mobile} {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
