import React from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import styled from 'styled-components';
import { ArrowRight } from 'react-feather';
import Button from '../../VuexyDesign/atoms/Button';
import { noDesktop, useIsMobile } from '../../style/constants';
import { useIsLoggedIn } from '../../api';

const DemoCommunity = () => {
  const { t } = useTranslation('home');
  const isLoggedIn = useIsLoggedIn();
  const isMobile = useIsMobile();
  return (
    <>
      <div>
        <div className="d-flex flex-column">
          <Container className="d-flex">
            <div className="textContainer">
              <div className="d-flex flex-column align-items-start">
                <div className="bg-primary rounded">
                  <span className="text-white px-1 custom-font-text-small-medium">
                    {t('home:demoCommunity') as string}
                  </span>
                </div>
                <h2 className="custom-heading-font-2 my-1">
                  {t('home:getFeedback') as string}
                </h2>
                <span className="mb-2 custom-font-text-medium-normal">
                  {t('home:testYourDemos') as string}
                </span>

                <div className="d-flex flex-row align-items-center">
                  <Link href={isLoggedIn ? '/upload' : '/register'}>
                    <Button
                      color="primary"
                      className="d-flex align-items-center"
                    >
                      <>
                        {isLoggedIn
                          ? (t('home:uploadNow') as string)
                          : (t('home:getStarted') as string)}{' '}
                      </>
                    </Button>
                  </Link>
                  <div className="ml-1 cursor-pointer">
                    <Link passHref href="/how-it-works/get-feedback">
                      <div>
                        <span className="custom-font-text-regular-medium">
                          {t('home:learnMore') as string}{' '}
                          <ArrowRight size={14} />
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="imageContainer d-flex">
              {isMobile ? (
                <Image
                  alt="chat box"
                  src="/static/images/home/chatBox_mobile.webp"
                  height={305}
                  width={315}
                  loading="lazy"
                />
              ) : (
                <Image
                  alt="chat box"
                  src="/static/images/home/chatBox_desktop.webp"
                  height={580}
                  width={593}
                  loading="lazy"
                />
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default DemoCommunity;

const Container = styled.div`
  align-items: center;
  @media ${noDesktop} {
    flex-direction: column-reverse;
  }

  .textContainer {
    width: 50%;
    @media ${noDesktop} {
      width: 100%;
    }
  }

  .imageContainer {
    width: 50%;
    justify-content: flex-end;
    @media ${noDesktop} {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;
